import { Input, Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SPOKESPERSONARTICLES } from "../../constants";
import userService from "../../Services/user.service";
// import GetFiltersData from "../Filters/GetFiltersData";
import BrandArticle from "./BrandArticle";

const SpokesPerson = () => {
  const [loader, setLoader] = useState(false);
  const [trackingId, setTrackingId] = useState();
  const [brandArticle, setBrandArticle] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [selectedOutlet, setSelectedOutlet] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({ count: 0 });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dateStatus, setDateStatus] = useState(true);

  function generateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  const SpokesArticles = async (selectedArray = null) => {
    setLoader(true);
    const bodyData = {
      outlet: selectedArray,
    };
    try {
      const BrandUrl = `${SPOKESPERSONARTICLES}?pageNumber=${currentPage}&StartDate=${startDate}&EndDate=${endDate}&pageSize=20`;
      const [response] = await Promise.all([
        userService.post(BrandUrl, bodyData),
      ]);

      if (response?.response?.status === "Ok") {
        setBrandArticle((old) => [...old, ...response?.result?.hits?.hits]);
        setTotalCount(response?.result?.hits?.total?.value);
      }
    } catch (error) {
      console.log(error?.message);
    } finally {
      setLoader(false);
    }
  };

  const urlBuilder = () => {
    for (let allKeys in selectedFilters) {
      if (selectedFilters[allKeys].length > 0 && allKeys === "Outlet") {
        //console.log(selectedFilters[allKeys], "check all values");
        let output = selectedFilters[allKeys]?.map((curItem) =>
          curItem.split(" ").slice(1).join(" ")
        );
        return output;
        // console.log(output, "check select and unselect values");
        // setSelectedOutlet(output);
      } else {
        setSelectedOutlet([]);
      }
    }
  };

  useEffect(() => {
    SpokesArticles(urlBuilder());
  }, [currentPage, startDate, endDate]);

  //   useEffect(() => {
  //     if (currentPage > 1) {
  //       setBrandArticle([]);
  //       setCurrentPage(1);
  //     } else if (selectedFilters) {
  //       setBrandArticle([]);
  //       SpokesArticles(urlBuilder());
  //     }
  //   }, [selectedFilters]);

  useEffect(() => {
    setTrackingId(generateUUID());
  }, []);

  const handleDateRangeChange = (e) => {
    const range = e.target.value;
    setDateRange(range);
    let calculatedStartDate = "";
    let calculatedEndDate = new Date().toISOString().split("T")[0];
    setEndDate(calculatedEndDate);

    switch (range) {
      case "past24Hours":
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        calculatedStartDate = yesterday.toISOString().split("T")[0];
        setDateStatus(true);
        break;
      case "last3Days":
        const threeDaysAgo = new Date();
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
        calculatedStartDate = threeDaysAgo.toISOString().split("T")[0];
        setDateStatus(true);
        break;
      case "last1Week":
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        calculatedStartDate = oneWeekAgo.toISOString().split("T")[0];
        setDateStatus(true);
        break;
      case "customDateRange":
        setDateStatus(false);
        break;
      default:
        setStartDate("");
        setDateStatus(true);
        return;
    }
    setStartDate(calculatedStartDate);
  };

  const loadMoreJourno = () => {
    setCurrentPage(currentPage + 1);
  };
  //    const articlesPerPage = 20;
  //   const handlePagination = (operation) => {
  //     setCurrentPage((prevPage) => {
  //       if (
  //         operation === "increment" &&
  //         currentPage * articlesPerPage < totalCount
  //       ) {
  //         return prevPage + 1;
  //       } else if (operation === "decrement" && prevPage > 1) {
  //         return prevPage - 1;
  //       }
  //       return prevPage;
  //     });
  //   };
  return (
    <>
      <div className="flex justify-end -mt-10  w-96 self-end">
        <div className="flex flex-col gap-2 text-sm md:flex-row">
          <div className="border-[1px] border-[#000] border-opacity-20 rounded-[5px]">
            <Select
              className="text-customGray"
              placeholder="Select Date Range"
              width={{ base: "100%", xl: "200px" }}
              size={"sm"}
              onChange={handleDateRangeChange}
              focusBorderColor="transparent"
              border={"none"}
            >
              <option value="past24Hours">Past 24 hours</option>
              <option value="last3Days">Last 3 days</option>
              <option value="last1Week">Last 1 week</option>
              <option value="customDateRange">Custom Date Range</option>
            </Select>
          </div>

          <div className="flex flex-col gap-2 md:flex-row">
            <div className="border-[1px] border-[#000] border-opacity-20 rounded-[5px]">
              <Input
                className="text-customGray  xl:w-[200px] w-full"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Start Date"
                variant="filled"
                sx={{
                  bg: "rgba(51, 51, 51, 0.1)",
                }}
                size={"sm"}
                isDisabled={dateStatus}
                focusBorderColor="transparent"
                border={"none"}
              />
            </div>
            <div className="border-[1px] border-[#000] border-opacity-20 rounded-[5px]">
              <Input
                className="text-customGray  xl:w-[200px] w-full"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="End Date"
                variant="filled"
                sx={{
                  bg: "rgba(51, 51, 51, 0.1)",
                }}
                size={"sm"}
                isDisabled={dateStatus}
                focusBorderColor="transparent"
                border={"none"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-12/12  py-6 pt-0  pr-0 section">
        <section className="flex gap-x-6">
          <aside className="flex flex-col hidden w-4/12 gap-6 md:flex md:w-7/12 lg:w-1/3">
            {/* <fieldset className="self-start w-full px-3 border border-gray-300 rounded-xl ">
              <h3 className="p-3  text-gray-900 uppercase">Filters</h3>
              <div className="w-full p-3">
                <div className="flex flex-wrap gap-2"></div>
                <GetFiltersData
                  trackingId={trackingId}
                  type="Outlet"
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                  url={`BrandDashboardArticleFilter`}
                  method="POST"
                />
              </div>
            </fieldset> */}
          </aside>
          <article className="flex flex-col flex-wrap self-start w-full gap-6">
            <div className="border border-gray-300 rounded-xl p-2 mb-4 bg-white ">
              <div className="text-md p-2 text-[#333] font-[500] border-b border-gray-300">
                List of Articles that Mention the Spokes Person
              </div>
              <BrandArticle
                articles={brandArticle}
                loading={loader}
                passData={loadMoreJourno}
                totalCount={totalCount}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default SpokesPerson;
