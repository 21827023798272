import React, { useEffect, useState } from "react";
import PieChart from "./PieChart";
import { ApexChart, SemiDountChart } from "./SemiDountChart";
import userService from "../../Services/user.service";
import {
  SHAREVOICE,
  BRANDSENTIMENT,
  BRANDLANGUAGE,
  CITYWISECOUNT,
} from "../../constants";
import ShareOfVoice from "./ShareOfVoice";

const data1 = BRANDSENTIMENT;
const data2 = SHAREVOICE;
const data3 = BRANDLANGUAGE;
const data4 = CITYWISECOUNT;

const ChartStats = ({ startDate, endDate }) => {
  const [loader, setLoader] = useState(false);
  const [languageCount, setLanguageCount] = useState([]);
  const [sentimentCount, setSentimentsCount] = useState([]);
  const [sentimentRatio, setSentimentRatio] = useState("");
  const [shareOfVoice, setShareOfVoice] = useState([]);
  const [cityCount, setCityCount] = useState([]);

  const graphAPI = async () => {
    setLoader(true);
    try {
      const url1 = `${data1}?StartDate=${startDate}&EndDate=${endDate}`;
      const url2 = `${data2}?StartDate=${startDate}&EndDate=${endDate}`;
      const url3 = `${data3}?StartDate=${startDate}&EndDate=${endDate}`;
      const url4 = `${data4}?StartDate=${startDate}&EndDate=${endDate}`;
      const [response1, response2, response3, response4] = await Promise.all([
        userService.post(url1),
        userService.post(url2),
        userService.post(url3),
        userService.post(url4),
      ]);

      if (response1?.response?.status === "Ok") {
        setSentimentsCount(
          response1?.result?.aggregations?.distinct_websites?.buckets
        );
        setSentimentRatio(response1?.sentimentRatio);
      }

      if (response2?.response?.status === "Ok") {
        setShareOfVoice(response2?.results);
      }

      if (response3?.response?.status === "Ok") {
        setLanguageCount(
          response3?.result?.aggregations?.distinct_websites?.buckets
        );
      }
      if (response4?.response?.status === "Ok") {
        setCityCount(
          response4?.result?.aggregations?.distinct_websites?.buckets
        );
      }
    } catch (error) {
      console.log(error, "check error");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    //api call LANGUAGAE WISE SPLIT AND SENTIMENT SCORE GRAPH
    graphAPI();
  }, [startDate, endDate]);
  return (
    <>
      <div className="flex flex-col gap-6 lg:flex-row">
        <fieldset className="relative w-12/12 md:w-12/12 lg:w-4/12 xl:w-5/12 border border-gray-300 rounded-md p-6">
          <legend className="px-2 text-sm text-customGray">
            City-wise coverage
          </legend>
          <PieChart data={cityCount} />
        </fieldset>
        <fieldset className="relative w-12/12 md:w-12/12 lg:w-4/12 xl:w-5/12 border border-gray-300 rounded-md p-6">
          <legend className="px-2 text-sm text-customGray">
            Language-wise coverage
          </legend>
          <PieChart data={languageCount} />
        </fieldset>
        <fieldset className="relative w-12/12 md:w-12/12 lg:w-4/12 xl:w-5/12 border border-gray-300 rounded-md p-6">
          <legend className="px-2 text-sm text-customGray">
            Share of voice
          </legend>
          <ShareOfVoice data={shareOfVoice} />
        </fieldset>
        <fieldset className="relative w-12/12 md:w-12/12 lg:w-4/12 xl:w-5/12 border border-gray-300 rounded-md p-6">
          <legend className="px-2 text-sm text-customGray">
            Sentiment Score
          </legend>
          <div className="relative">
            <div className="absolute text-lg font-semibold text-gray-900 top-24 left-28">
              {sentimentRatio}
            </div>
            <ApexChart data={sentimentCount} />
          </div>
        </fieldset>
      </div>
    </>
  );
};

export default ChartStats;
