import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { MaterialIcon } from "material-icons";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../Redux/Action/UserAuth";
import ErrorToast from "../utils/ErrorToast";
import SuccessToast from "../utils/SuccessToast";
import WarningToast from "../utils/WarningToast";
import { logout_API } from "../../constants";
import SessionOut from "../SessionOut/SessionOut";
import { mobileMenu } from "../../Redux/Action/Settings";
import { useRef } from "react";
import service from "../../Services/auth.service";
import Searchbar from "./Searchbar";
import { USERINFO } from "../../constants";
import userService from "../../Services/user.service";
import logo from "../assets/skribe-logo.png";
import TawkMessenger from "../Chat/TawkMessenger";

const Header = () => {
  const openDD = useRef();
  const closeDD = useRef();
  const [socialDropDown, setSocialDropDown] = useState(false);

  const dispatch = useDispatch();
  const [warningactive, warningSetActive] = useState(false);
  const [erroractive, errorSetActive] = useState(false);
  const [successactive, successSetActive] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const location = useLocation();
  // const errorAlert = useSelector((state) => state.settings.errorAlert);
  // const warningAlert = useSelector((state) => state.settings.warningAlert);
  // const successAlert = useSelector((state) => state.settings.successAlert);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };
    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  const mainNav = [
    {
      name: "Home",
      //url: "journalist-search",
      url: "dashboard",
    },
    // {
    //   name: "Brand",
    //   url: "brandDashboard",
    // },
    {
      name: "Social",
      url: "",
      children: [
        {
          name: "X",
          url: "X",
        },
        {
          name: "Instagram",
          url: "instagram",
          // url: "insta-detail",
        },
        {
          name: "Podcast",
          url: "podcast",
        },

        // {
        //   name: "Podcast Listing",
        //   url: "podcast-listing",
        // },
        // {
        //   name: "Podcast Detail",
        //   url: "podcast-detail",
        // },
      ],
    },
    {
      name: "MyCRM",
      url: "mycrm",
    },
    {
      name: "Campaigns",
      url: "campaign-manager",
    },
  ];

  // useEffect(() => {
  //   let timeout = setTimeout(() => {
  //     warningSetActive(false);
  //   }, 5000);

  //   return () => {
  //     clearTimeout(timeout);
  //     warningSetActive(true);
  //   };
  // }, [warningAlert.trigger]);

  // useEffect(() => {
  //   let timeout = setTimeout(() => {
  //     errorSetActive(false);
  //   }, 5000);

  //   return () => {
  //     clearTimeout(timeout);
  //     errorSetActive(true);
  //   };
  // }, [errorAlert.trigger]);

  // useEffect(() => {
  //   let timeout = setTimeout(() => {
  //     successSetActive(false);
  //   }, 5000);

  //   return () => {
  //     clearTimeout(timeout);
  //     successSetActive(true);
  //   };
  // }, [successAlert.trigger]);

  const applogout = () => {
    userService
      .post(`Authenticate/logout`, "")
      .then((res) => {})
      .catch(() => {});
    //localStorage.clear();
    service.logout();
    window.location.href = "/";
  };

  useEffect(() => {
    window.addEventListener("click", outSide);
    return () => {
      document.removeEventListener("click", outSide);
    };
  }, []);
  const outSide = (e) => {
    if (openDD.current && !openDD.current.contains(e.target)) {
      setSocialDropDown(false);
    }
    if (closeDD.current && closeDD.current.contains(e.target)) {
      setSocialDropDown(true);
    }
  };

  const postTracking = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const Url = location.pathname.split("/");
    const pageName = Url[1] || "home";
    let pageId;
    if (
      [
        "journalistByOutlet",
        "OutletEditor",
        "BureauChief",
        "Columnist",
        "Supplements",
        "International",
      ].includes(pageName)
    ) {
      pageId = Url[3] || "";
    } else {
      pageId = Url[2] || "";
    }
    const trackingData = {
      userId: userInfo?.id,
      pageName: pageName,
      clickId: pageId,
    };
    userService
      .post(`PostTracking`, trackingData)
      .then((res) => {
        console.log("Tracking data posted successfully");
      })
      .catch((error) => {
        console.log(error, "Error posting tracking data");
      });
  };
  useEffect(() => {
    postTracking();
  }, [location.pathname]);
  return (
    <>
      <TawkMessenger />

      {/* <SessionOut /> */}
      {!isOnline && <ErrorToast msg="It seems your Internet is not working" />}
      {/* {errorAlert.msg && erroractive && <ErrorToast msg={errorAlert.msg} />}
      {warningAlert.msg && warningactive && (
        <WarningToast msg={warningAlert.msg} />
      )}
      {successAlert.msg && successactive && (
        <SuccessToast msg={successAlert.msg} />
      )} */}
      <header className="sticky top-0 z-50 flex flex-col-reverse justify-between border-b  border-gray-300 bg-[#6521AD] p-3 md:flex-row md:items-center lg:flex-row">
        <Searchbar />
        <div className="flex items-center justify-between mb-4 gap-x-5 md:mb-0">
          <div className="flex flex-row gap-2">
            <Link onClick={() => dispatch(mobileMenu())} className="lg:hidden">
              <span className="px-3 py-2 text-lg text-gray-500 border border-gray-500 rounded-lg material-icons-outlined hover:text-gray-700">
                menu
              </span>
            </Link>
            <div className="pr-2 lg:hidden">
              <Link to="/dashboard">
                <img src={logo} alt="image" width="80" />
              </Link>
            </div>
          </div>
          <ul
            role="Primary Navigation"
            aria-label="Main Navigation"
            className="flex hidden pb-5 gap-x-8 md:pb-0 lg:flex"
          >
            {mainNav.map((curElem, index) => (
              <li key={index} className="relative">
                {/* <Link to="dashboard" className="flex"> */}
                {curElem?.name === "Social" ? (
                  <span
                    ref={openDD}
                    className="flex items-center text-white cursor-pointer gap-x-1"
                    onClick={() => setSocialDropDown(!socialDropDown)}
                  >
                    {curElem.name}
                    {curElem?.children?.length > 0 && (
                      <span
                        className={
                          socialDropDown
                            ? "material-icons-outlined icon-16  rotate-180 transition-all duration-500"
                            : "material-icons-outlined  icon-16 -rotate-360  transition-all duration-500"
                        }
                      >
                        expand_more
                      </span>
                    )}
                  </span>
                ) : (
                  <Link
                    to={curElem.url}
                    className="flex items-center text-white gap-x-1"
                  >
                    {curElem.name}
                  </Link>
                )}
                {socialDropDown && (
                  <ul
                    ref={closeDD}
                    className="absolute -left-1 mt-2 w-32 bg-[#6521AD]  shadow-2xl"
                  >
                    {curElem?.name === "Social" &&
                      curElem?.children?.map((curElem, index) => {
                        return (
                          <div key={index}>
                            <li className="">
                              <Link
                                className="block px-2 py-1 text-white hover:bg-[#591b9b]"
                                to={curElem.url}
                              >
                                {curElem.name}
                              </Link>
                            </li>
                          </div>
                        );
                      })}
                  </ul>
                )}
              </li>
            ))}
            {/* <li>
              <Link to="mycrm">MyCRM</Link>
            </li> */}
          </ul>

          <div className="flex leading-none gap-x-5">
            {/* <Link to="Geo Location">
              <span className="text-lg text-white material-icons-outlined">
                settings
              </span>
            </Link> */}
            <Link to="accountSettings">
              <span className="text-lg text-white material-icons-outlined">
                apps
              </span>
            </Link>
            <span
              // onClick={() => alert("Notification will be received")}
              className="relative cursor-pointer"
            >
              <span className="text-lg text-white material-icons-outlined">
                notifications
              </span>
              {/* <span className="absolute right-[2px] top-[3px] block h-2 w-2 rounded-full bg-red-500"></span> */}
            </span>

            <span
              //onClick={() => dispatch(logOut(logout_API, authToken))}
              onClick={applogout}
              className="text-lg text-white cursor-pointer material-icons-outlined"
            >
              power_settings_new
            </span>
          </div>
        </div>
      </header>
    </>
  );
};

export default React.memo(Header);
