import { Input, Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BRANDARTICLES } from "../../constants";
import userService from "../../Services/user.service";
import GetFiltersData from "../Filters/GetFiltersData";
import BrandArticle from "./BrandArticle";

const Brands = ({ tab }) => {
  const [loader, setLoader] = useState(false);
  const [trackingId, setTrackingId] = useState();
  const [brandArticle, setBrandArticle] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [selectedOutlet, setSelectedOutlet] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({ count: 0 });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dateStatus, setDateStatus] = useState(true);

  useEffect(() => {
    setTrackingId(generateUUID());
  }, []);

  // Function to generate UUID
  function generateUUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  const BrandArticles = async (selectedArray = null) => {
    setLoader(true);
    const bodyData = {
      outlet: selectedArray,
    };
    const BrandUrl = `${BRANDARTICLES}?pageNumber=${currentPage}&StartDate=${startDate}&EndDate=${endDate}&pageSize=20`;

    try {
      const response = await userService.post(BrandUrl, bodyData);
      if (response?.response?.status === "Ok") {
        setBrandArticle(response?.result?.hits?.hits);
        setTotalCount(response?.result?.hits?.total?.value);
      }
    } catch (error) {
      console.log(error?.message);
    } finally {
      setLoader(false);
    }
  };

  // Function to build URL for filters
  const urlBuilder = () => {
    if (selectedFilters.Outlet && selectedFilters.Outlet.length > 0) {
      return selectedFilters.Outlet.map((curItem) =>
        curItem.split(" ").slice(1).join(" ")
      );
    } else {
      setSelectedOutlet([]);
      return [];
    }
  };

  // Handle Date Range Change
  const handleDateRangeChange = (e) => {
    const range = e.target.value;
    setDateRange(range);
    let calculatedStartDate = "";
    let calculatedEndDate = new Date().toISOString().split("T")[0];
    setEndDate(calculatedEndDate);

    switch (range) {
      case "past24Hours":
        calculatedStartDate = new Date(Date.now() - 86400000)
          .toISOString()
          .split("T")[0];
        setDateStatus(true);
        break;
      case "last3Days":
        calculatedStartDate = new Date(Date.now() - 3 * 86400000)
          .toISOString()
          .split("T")[0];
        setDateStatus(true);
        break;
      case "last1Week":
        calculatedStartDate = new Date(Date.now() - 7 * 86400000)
          .toISOString()
          .split("T")[0];
        setDateStatus(true);
        break;
      case "customDateRange":
        setDateStatus(false);
        return; // No need to update start date here
      default:
        setStartDate("");
        setDateStatus(true);
        return;
    }
    setStartDate(calculatedStartDate);
    setCurrentPage(1); // Reset page to 1 when date range changes
  };

  useEffect(() => {
    if (selectedFilters) {
      if (currentPage !== 1) {
        setBrandArticle([]); // Clear existing articles
        setCurrentPage(1); // Reset to page 1
      }
      if (selectedFilters.count > 0) {
        BrandArticles(urlBuilder());
      }
    }
  }, [selectedFilters]);

  // Effect to handle when selected filters or date range change
  useEffect(() => {
    if (startDate || endDate) {
      // Reset page to 1 only if the current page is greater than 1
      setBrandArticle([]); // Clear previous results
      BrandArticles(urlBuilder());
    }
  }, [startDate, endDate, selectedFilters]);

  // Effect to handle page changes (unchanged)
  useEffect(() => {
    if (currentPage > 1 || brandArticle.length === 0) {
      BrandArticles(urlBuilder());
    }
  }, [currentPage]);

  const removeSelected = (type, curElem) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      updatedFilters[type] = updatedFilters[type].filter(
        (item) => item !== curElem
      );
      setCurrentPage(1);
      // If the array is empty, remove the filter key
      if (updatedFilters[type].length === 0) {
        delete updatedFilters[type];
      }

      // Update the filter count
      updatedFilters.count = Object.keys(updatedFilters).reduce(
        (total, key) =>
          Array.isArray(updatedFilters[key])
            ? total + updatedFilters[key].length
            : total,
        0
      );

      return updatedFilters;
    });
  };

  return (
    <>
      <div className="flex self-end justify-end -mt-10 w-96">
        <div className="flex flex-col gap-2 text-sm md:flex-row">
          <div className="border-[1px] border-[#000] border-opacity-20 rounded-[5px]">
            <Select
              className="text-customGray"
              placeholder="Select Date Range"
              width={{ base: "100%", xl: "200px" }}
              size={"sm"}
              onChange={handleDateRangeChange}
              focusBorderColor="transparent"
              border={"none"}
            >
              <option value="past24Hours">Past 24 hours</option>
              <option value="last3Days">Last 3 days</option>
              <option value="last1Week">Last 1 week</option>
              <option value="customDateRange">Custom Date Range</option>
            </Select>
          </div>

          <div className="flex flex-col gap-2 md:flex-row">
            <div className="border-[1px] border-[#000] border-opacity-20 rounded-[5px]">
              <Input
                className="text-customGray  xl:w-[200px] w-full"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Start Date"
                variant="filled"
                sx={{
                  bg: "rgba(51, 51, 51, 0.1)",
                }}
                size={"sm"}
                isDisabled={dateStatus}
                focusBorderColor="transparent"
                border={"none"}
              />
            </div>
            <div className="border-[1px] border-[#000] border-opacity-20 rounded-[5px]">
              <Input
                className="text-customGray  xl:w-[200px] w-full"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="End Date"
                variant="filled"
                sx={{
                  bg: "rgba(51, 51, 51, 0.1)",
                }}
                size={"sm"}
                isDisabled={dateStatus}
                focusBorderColor="transparent"
                border={"none"}
              />
            </div>
          </div>
        </div>
      </div>
      {Object?.keys(selectedFilters).length > 0 && (
        <div className="w-11/12 pr-0 section">
          <div className="flex flex-wrap gap-2">
            <div className="flex flex-wrap items-center px-2 rounded-md">
              {Object?.keys(selectedFilters)?.map((key, index) => (
                <div key={index} className="flex items-center">
                  {key !== "count" && key + ":"}
                  {selectedFilters[key]?.length > 0 &&
                    selectedFilters[key]?.map((curItem, ind) => {
                      return (
                        <div key={ind}>
                          <div className="flex items-center px-2 m-1 border border-gray-400 rounded-md">
                            {curItem.split(" ").map((curElem, index) => {
                              if (index !== 0) {
                                const formattedWord =
                                  curElem.charAt(0).toUpperCase() +
                                  curElem.slice(1).toLowerCase();
                                return (
                                  <span key={index} className="text-gray-600">
                                    {formattedWord}&nbsp;
                                  </span>
                                );
                              }
                              return null; // Ensure the function always returns something
                            })}
                            <span
                              onClick={() => removeSelected(key, curItem)}
                              className="pl-1 text-gray-400 rounded-full cursor-pointer material-icons-outlined icon-16 hover:text-gray-800"
                            >
                              close
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="py-6 pt-0 pr-0 w-12/12 section">
        <section className="flex gap-x-6">
          <aside className="flex flex-col hidden w-4/12 gap-6 md:flex md:w-7/12 lg:w-1/3">
            <fieldset className="self-start w-full px-3 border border-gray-300 rounded-xl ">
              <h3 className="p-3 text-gray-900 uppercase">Filters</h3>
              <div className="w-full p-3">
                <div className="flex flex-wrap gap-2"></div>
                <GetFiltersData
                  trackingId={trackingId}
                  type="Outlet"
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                  url={`BrandDashboardArticleFilter`}
                  method="POST"
                />
              </div>
            </fieldset>
          </aside>
          <article className="flex flex-col flex-wrap self-start w-full gap-6">
            <div className="p-2 mb-4 bg-white border border-gray-300 rounded-xl ">
              <div className="text-md p-2 text-[#333] font-[500] border-b border-gray-300">
                List of Articles that Mention the Brand
              </div>
              <BrandArticle
                articles={brandArticle}
                loading={loader}
                setCurrentPage={setCurrentPage}
                totalCount={totalCount}
                currentPage={currentPage}
              />
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default Brands;
