import React, { useState } from "react";
import { Input, Select } from "@chakra-ui/react";
import ChartStats from "./ChartStats";
import TopJournalist from "./TopJournalist";
import Articles from "./Brands";
import Brands from "./Brands";
import { Link } from "react-router-dom";
import SpokesPerson from "./SpokesPerson";

const BrandDashboard = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dateStatus, setDateStatus] = useState(true);
  const [isSpokespersonVisible, setIsSpokespersonVisible] = useState(true);

  const handleDateRangeChange = (e) => {
    const range = e.target.value;
    setDateRange(range);
    let calculatedStartDate = "";
    let calculatedEndDate = new Date().toISOString().split("T")[0];
    setEndDate(calculatedEndDate);

    switch (range) {
      case "past24Hours":
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        calculatedStartDate = yesterday.toISOString().split("T")[0];
        setDateStatus(true);
        break;
      case "last3Days":
        const threeDaysAgo = new Date();
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
        calculatedStartDate = threeDaysAgo.toISOString().split("T")[0];
        setDateStatus(true);
        break;
      case "last1Week":
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        calculatedStartDate = oneWeekAgo.toISOString().split("T")[0];
        setDateStatus(true);
        break;
      case "customDateRange":
        setDateStatus(false);
        break;
      default:
        setStartDate("");
        setDateStatus(true);
        return;
    }
    setStartDate(calculatedStartDate);
  };
  return (
    <>
      <div className="min-h-screen font-poppins">
        <div className="px-8 py-4 xl:w-11/12 w-12/12 xl:pl-8 section flex items-center justify-between">
          <div className="flex flex-col mt-0 ml-4">
            <ul className="flex items-center text-sm text-gray-400 gap-x-1">
              <li className="flex items-center">
                Home
                <span className="items-center text-gray-400 material-icons-outlined b-font">
                  navigate_next
                </span>
              </li>
              <li className="flex items-center">Brand Dashboard</li>
            </ul>
          </div>

          <div className="flex flex-col justify-end gap-2 text-sm md:flex-row items-center">
            <div className="border-[1px] border-[#000] border-opacity-20 rounded-[5px]">
              <Select
                className="text-customGray"
                placeholder="Select Date Range"
                width={{ base: "100%", xl: "200px" }}
                size={"sm"}
                onChange={handleDateRangeChange}
                focusBorderColor="transparent"
                border={"none"}
              >
                <option value="past24Hours">Past 24 hours</option>
                <option value="last3Days">Last 3 days</option>
                <option value="last1Week">Last 1 week</option>
                <option value="customDateRange">Custom Date Range</option>
              </Select>
            </div>

            <div className="flex flex-col gap-2 md:flex-row">
              <div className="border-[1px] border-[#000] border-opacity-20 rounded-[5px]">
                <Input
                  className="text-customGray  xl:w-[200px] w-full"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  placeholder="Start Date"
                  variant="filled"
                  sx={{
                    bg: "rgba(51, 51, 51, 0.1)",
                  }}
                  size={"sm"}
                  isDisabled={dateStatus}
                  focusBorderColor="transparent"
                  border={"none"}
                />
              </div>
              <div className="border-[1px] border-[#000] border-opacity-20 rounded-[5px]">
                <Input
                  className="text-customGray  xl:w-[200px] w-full"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  placeholder="End Date"
                  variant="filled"
                  sx={{
                    bg: "rgba(51, 51, 51, 0.1)",
                  }}
                  size={"sm"}
                  isDisabled={dateStatus}
                  focusBorderColor="transparent"
                  border={"none"}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="mb-4" />
        <div className="px-8 pb-6 xl:w-11/12 w-12/12 xl:pl-8 section">
          <div className="flex flex-col w-full gap-6 ml-4">
            <ChartStats startDate={startDate} endDate={endDate} />
            <TopJournalist startDate={startDate} endDate={endDate} />
            <p className="font-[500] text-[18px] text-[#333] ">Articles:</p>
            <div className="flex flex-col justify-between gap-4 md:items-center md:flex-row">
              <div>
                <ul className="flex text-[15px] text-[#666] cursor-pointer gap-x-2">
                  <li>
                    <Link
                      onClick={() => setIsSpokespersonVisible(true)}
                      className={`px-2 pb-1 hover:border-b-2 border-[#000] ${
                        isSpokespersonVisible
                          ? "border-b-2 text-[#000] font-[500]"
                          : ""
                      }`}
                    >
                      Brands
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => setIsSpokespersonVisible(false)}
                      className={`px-2 pb-1 hover:border-b-2 border-[#000] ${
                        !isSpokespersonVisible
                          ? "border-b-2 text-[#000] font-[500]"
                          : ""
                      }`}
                    >
                      Spokesperson
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {isSpokespersonVisible ? (
              <Brands tab={isSpokespersonVisible} />
            ) : (
              <SpokesPerson />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandDashboard;
