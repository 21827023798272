import React, { useEffect, useState } from "react";
import JournalistTable from "./JournalistTable";
import { BYBRANDTOPJOURNO } from "../../constants";
import userService from "../../Services/user.service";

const TopJournalist = ({ startDate, endDate }) => {
  const [loader, setLoader] = useState(false);
  const [journalistByBrand, setJournalistByBrand] = useState([]);
  const [journalistByCompetitor, setJournalistByCompetitor] = useState([]);
  const BrandCompApi = async () => {
    setLoader(true);
    try {
      const url1 = `${BYBRANDTOPJOURNO}?Brand_Competitor=Brand&StartDate=${startDate}&EndDate=${endDate}`;
      const url2 = `${BYBRANDTOPJOURNO}?Brand_Competitor=Competitor&StartDate=${startDate}&EndDate=${endDate}`;
      const [res1, res2] = await Promise.all([
        userService.post(url1),
        userService.post(url2),
      ]);

      if (res1?.response?.status === "Ok") {
        setJournalistByBrand(res1?.journalistCounts);
      }
      if (res2?.response?.status === "Ok") {
        setJournalistByCompetitor(res2?.journalistCounts);
      }
    } catch (error) {
      console.log(error?.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    BrandCompApi();
  }, [startDate, endDate]);
  return (
    <>
      <div className="flex justify-between w-full gap-6">
        <div className="w-1/2">
          <p className="font-[500] text-[18px] text-[#333333]">
            Top Journalists (Brand):
          </p>
          <p className="text-[#999] text-[14px] font-[400]">
            Top Journalists that Mentioned the Brand
          </p>

          <JournalistTable data={journalistByBrand} loading={loader} />
        </div>
        <div className="w-1/2">
          <p className="font-[500] text-[18px] text-[#333333]">
            Top Journalists (Competition):
          </p>
          <p className="text-[#999] text-[14px] font-[400]">
            Top Journalists that Mentioned the Competition
          </p>
          <JournalistTable data={journalistByCompetitor} loading={loader} />
        </div>
      </div>
    </>
  );
};

export default TopJournalist;
