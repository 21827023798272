import React, { useState } from "react";
import { baseURL } from "../../constants";
import noUser from "../assets/noUser.png";

const JournalistTable = ({ data, loading }) => {
  return (
    <>
      <div className="mt-4 w-full border rounded-[12px] ">
        <div className="bg-[#E7E7E7] text-[14px] font-[400] justify-between flex py-4 rounded-t-[12px]">
          <div className="flex items-center justify-center w-1/2">
            Journalists
          </div>
          <div className="flex items-center justify-center w-1/2">Mentions</div>
        </div>
        <div className="h-[550px] overflow-scroll overflow-x-hidden">
          {data ? (
            data?.length > 0 ? (
              data?.map((curItem) => {
                return (
                  <div className="flex items-center justify-between ">
                    <div className="flex items-center w-1/2 gap-5 px-4 py-2 basis-2/3  border-r border-gray-[#E7E7E7]">
                      <div>
                        <img
                          src={
                            !!curItem?.photo && curItem?.photo
                              ? baseURL + curItem?.photo
                              : noUser
                          }
                          alt=""
                          className="h-14 w-14"
                        />
                      </div>
                      <div>
                        <p className="text-[#333333] font-[500] text-[14px]">
                          {" "}
                          {curItem?.journalistName}
                        </p>
                        <p className="text-[#666666] font-[500] text-[12px]">
                          {curItem?.journalistOutlet}
                        </p>
                      </div>
                    </div>
                    <div className="w-1/2 text-center font-[500] text-[14px] text-[#000]">
                      {curItem?.count}
                    </div>
                  </div>
                );
              })
            ) : loading ? (
              <p className="p-6 text-[#666666] font-[500] text-lg text-center">
                Loading...
              </p>
            ) : (
              <p className="p-6 text-[#666666] font-[500] text-lg text-center">
                No Record Found
              </p>
            )
          ) : (
            <p className="p-6 text-[#666666] font-[500] text-lg text-center">
              No Record Found
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default JournalistTable;
