import React, { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";

const ShareOfVoice = ({ data = [] }) => {
  let language = useMemo(() => data?.map((curElem) => curElem?.name), [data]);
  let counting = useMemo(() => data?.map((curElem) => curElem?.count), [data]);
  const options = useMemo(
    () => ({
      labels: language,
    }),
    [language] // Recalculate only when label changes
  );

  const series = counting;

  if (data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div className="px-1 py-4">
      <Chart options={options} series={series} type="pie" height={"500px"} />
    </div>
  );
};

export default ShareOfVoice;
